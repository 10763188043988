/**
 * Crm Routes
 */
/* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PerformanceReviewDetailsForm from './detailsForm'; 


const PerformanceDetailSetupRoute = ({ match }) => {
   return (
      <Switch>
         <Route path={`${match.url}/:id`} component={PerformanceReviewDetailsForm} />
      </Switch>
   )
};

export default PerformanceDetailSetupRoute;
