/**
 * Crm Routes
 */
/* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BulkUpload from './bulkUpload';
import CreateEmployeeForm from './createForm';
import EmployeeDetailSetupRoute from './detail';
import EmployeeList from './list'; 
import RosterForm from './roster/form.jsx'; 
const EmployeeModuleSetupRoute = ({ match }) => {
   
   return (
   <Switch>
      {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/admin`} /> */} 
      <Route path={`${match.url}/RosterForm`} component={RosterForm} />
      <Route path={`${match.url}/create`} component={CreateEmployeeForm} />
      <Route path={`${match.url}/bulk-upload`} component={BulkUpload} />
      <Route path={`${match.url}/detail`} component={EmployeeDetailSetupRoute} />
      <Route path={`${match.url}`} component={EmployeeList} />
     
   </Switch>
)};

export default EmployeeModuleSetupRoute;
