import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Offerletter from './Offerletter';
import CandidateInfo from './Candidateinfo';
import CandidateInfoform from './Candidateinfo/form';
import JobResponse from '../Hire/hApplicants/hApplicantForm/JobResponse';
// import Requisition from './Requisition/index';
const Onboarding = ({ match }) => {
   return (
   <Switch>
      <Route path={`${match.url}/offerletter`} component={Offerletter} />
       <Route path={`${match.url}/Candidate`} component={CandidateInfo} />
       <Route path={`${match.url}/CandidateForm`} component={CandidateInfoform} />
       <Route path={`${match.url}/successMessage`} component={JobResponse} />
      {/* <Route path={`${match.url}/requisition`} component={Requisition} />      */}
   </Switch>
)};

export default Onboarding;