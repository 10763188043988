/**
 * Crm Routes
 */
/* eslint-disable */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getUserType } from '../../../utility';
import Notification from '../Notification';
import Admindashboard from './admindashboard';
import Employeedashboard from './employeedashboard';
import CompanyAdminDashboard from './companyadmindashboard'; 
import NewSocialShare from './NewSocialshare';
const isCompanyAdmin = getUserType() == 'COMPANY_ADMIN';
const isEmployee = getUserType() == 'EMPLOYEE';

const DashboardRoute = ({ match }) => {  
   return (
   <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
      <Route path={`${match.url}/dashboard`} render={(props) => isEmployee? <NewSocialShare></NewSocialShare>: isCompanyAdmin?<NewSocialShare></NewSocialShare>:<Admindashboard></Admindashboard>}/>
      <Route path={`${match.url}/NewSocialShare`} component={NewSocialShare} /> 
      <Route path={`${match.url}/notification`} component={Notification} />
   </Switch>
)};

export default DashboardRoute;
