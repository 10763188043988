import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormGroup } from 'reactstrap';
import { getUserType, getEmployeeId, getPermission, verifyApprovalPermission } from '../../../utility';
import EmployeeDropdown from '../../ModuleSetup/Dropdown/EmployeeDropdown';
import LeaveTypeDropdown from '../../ModuleSetup/Dropdown/LeaveTypeDropdown';
import { saveLeave,getEmployeeLeaveInformation } from './service';
import { LeaveSchema } from './validation';
import { differenceInDays } from 'date-fns'
toast.configure();
const isCompanyAdmin = getUserType() == 'COMPANY_ADMIN';

export default class LeaveForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            leaveId: 0,
            leaveBalance: -1,
            leaveEmployeeId: 0,
            employeeLeaveCount: 0,
            employeeStartDate: "",
            employeeEndDate: "",
            leave: props.leave || {
                id: 0,
                employeeId: props.employeeId || getEmployeeId(),
                employee: { id: props.employeeId || getEmployeeId() },
                attachmentRequired: ""

            }
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.leave && nextProps.leave != prevState.leave) {
            return ({ leave: nextProps.leave })
        } else if (!nextProps.leave) {
            return ({
                leave: {
                    id: 0,
                    employeeId: nextProps.employeeId || getEmployeeId(),
                    employee: { id: nextProps.employeeId || getEmployeeId() }
                }
            })
        }

        return null;
    }
    save = (data, action) => {
        data["startDate"] = new Date(`${data["startDate"]} GMT`).toISOString();
        data["endDate"] = new Date(`${data["endDate"]} GMT`).toISOString();
        action.setSubmitting(true);
        let leaveData = {...data, startDatedayType: data.startDatedayType == null?0:data.startDatedayType, endDatedayType: data.endDatedayType == null?0:data.endDatedayType}
        saveLeave(leaveData).then(res => {
            if (res.status == "OK") {
                toast.success(res.message);
                this.props.showAlert('submit');
                this.props.updateList(res.data);
            } else {
                toast.error(res.message);
            }
            action.setSubmitting(false)
        }).catch(err => {
            console.error(err);
            toast.error("Error while applying leave");

            action.setSubmitting(false);
        })
    }
    // employee leave count
    handleEmployeeLeaveCount = (startDate,endDate) => {
        let {employeeStartDate,employeeEndDate} = this.state;
        if(startDate != ""){
            this.setState({employeeStartDate: startDate})
        }
        if(endDate != ""){
            this.setState({employeeEndDate: endDate})
        }
        if((employeeStartDate != "" || startDate != "") && (employeeEndDate != "" || endDate != "" )){
            const date1 = new Date(endDate != ""?endDate:employeeEndDate);
            const date2 = new Date(startDate != ""?startDate:employeeStartDate);
            const result = differenceInDays(date1, date2);
            this.setState({employeeLeaveCount: result+1})
        }
        

    }
    handleLeaveBalance = (leaveTypeId) => {
        getEmployeeLeaveInformation(leaveTypeId,this.state.leaveEmployeeId).then(res => {
            if (res.status == "OK") {
                this.setState({leaveBalance: res.data.leaveBalance})
            }
          })
         

    }
    handleLeaveDropdownChange = (leaveTypeId, attachmentRequired) => {
        this.setState({
            isAttachmentRequired: attachmentRequired
        });
         this.handleLeaveBalance(leaveTypeId);
    }
    render() {
        const { leave, isAttachmentRequired } = this.state;
        leave.file = "";
        leave.leaveTypeId = leave.leaveType?.id;
        leave.employeeId = leave.employee?.id || getEmployeeId();
        let isHidden = leave.employeeId != 0 ? true : false;
        isHidden = isCompanyAdmin ? false : isHidden;
        return (
            <div>

                <Formik
                    enableReinitialize={true}
                    initialValues={leave}
                    onSubmit={this.save}
                    validationSchema={LeaveSchema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        setSubmitting
                        /* and other goodies */
                    }) => (
                        <Form>

                            {(verifyApprovalPermission("LEAVE") || isCompanyAdmin) &&
                                <FormGroup hidden={isHidden}>
                                    <label>Employee
                                        <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Field name="employeeId" render={field => {
                                        return <EmployeeDropdown permission={getPermission("LEAVE", "EDIT")} defaultValue={values.employee?.id} onChange={e => {
                                            setFieldValue("employeeId", e.target.value);
                                            this.setState({leaveEmployeeId: e.target.value})
                                            this.setState({leaveBalance: -1})
                                            setFieldValue("employee", { id: e.target.value });
                                        }}></EmployeeDropdown>
                                    }}></Field>
                                    <ErrorMessage name="employeeId">
                                        {msg => <div style={{ color: 'red' }}>{msg}</div>}
                                    </ErrorMessage>
                                </FormGroup>}


                            <FormGroup>
                                <label>Leave Type
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <Field name="leaveTypeId" render={field => {
                                    return <LeaveTypeDropdown employeeId={values.employee?.id} defaultValue={values.leaveType?.id} setFieldValue={setFieldValue} onChange={ 
                                        this.handleLeaveDropdownChange  
                                     
                                    } ></LeaveTypeDropdown>
                                }}></Field>
                                <ErrorMessage name="leaveTypeId">
                                    {msg => <div style={{ color: 'red' }}>{msg}</div>}
                                </ErrorMessage>
                            </FormGroup>
                            {this.state.leaveBalance > -1 && 
                              <div style={{display: "flex", justifyContent: "flex-end", paddingRight: "10px"}}>
                                <h5 style={{color:"#3498db"}}>Available Balance : <span style={{color: "black"}}><b>{parseFloat(this.state.leaveBalance).toFixed(2)}</b></span></h5>
                                
                              </div>
                            }

                            <FormGroup>
                                <label>Leave Reason
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <Field name="leaveReason" defaultValue={values.leaveReason} className="form-control"></Field>
                                <ErrorMessage name="leaveReason">
                                    {msg => <div style={{ color: 'red' }}>{msg}</div>}
                                </ErrorMessage>
                            </FormGroup>
                            <div className="row">
                                <div className="col-md-12">
                                <FormGroup>
                                <label>Start Date
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <Field name="startDate" type="date" defaultValue={values.startDate} className="form-control"
                                onChange={(e) => {
                                    setFieldValue('startDate', e.target.value);
                                    this.handleEmployeeLeaveCount(e.target.value,"")
                                }}></Field>
                                <ErrorMessage name="startDate">
                                    {msg => <div style={{ color: 'red' }}>{msg}</div>}
                                </ErrorMessage>
                            </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                            <FormGroup>
                                <label>End Date
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <Field name="endDate" type="date" defaultValue={values.endDate} className="form-control"
                                 onChange={(e) => {
                                    setFieldValue('endDate', e.target.value);
                                    this.handleEmployeeLeaveCount("",e.target.value)
                                }}
                                ></Field>
                                <ErrorMessage name="endDate">
                                    {msg => <div style={{ color: 'red' }}>{msg}</div>}
                                </ErrorMessage>
                            </FormGroup>
                            </div>
                            </div>
                            {this.state.employeeLeaveCount > 0 && 
                              <div style={{display: "flex", justifyContent: "flex-end", paddingRight: "10px"}}>
                                <h5 style={{color:"#3498db"}}>Day(s) Count : <span style={{color: "black"}}><b>{this.state.employeeLeaveCount}</b></span></h5>
                                
                              </div>
                            }
                            {isAttachmentRequired && <FormGroup>
                                <label>Leave Document
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <input name="file" type="file" required className="form-control" onChange={e => {
                                    setFieldValue('file', e.currentTarget.files[0]);
                                }}></input>
                                <ErrorMessage name="file">
                                    {msg => <div style={{ color: 'red' }}>{msg}</div>}
                                </ErrorMessage>
                            </FormGroup>}
                            <input type="submit" className="btn btn-primary" value={this.state.leave.id > 0 ? "Update" : "Apply"} />

                        </Form>
                    )
                    }
                </Formik>
            </div>
        )
    }
}
