import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Division from './Division';
import Grades from './Grades';
import Section from './Section';
import Function from './Function';
import JobTitles from './JobTitles';
import Jobdescription from './Jobdescription';
import OrgSetupMain from './OrgSetupMain';
import JobDescription from './Jobdescription/JobdescriptionApproval';  
import OrganizationChartLanding from './OrganizationChartLading';

const OrganisationchartRoute = ({ match }) => {
   return (
   <Switch>
      <Route path={`${match.url}/orgchart`} component={OrgSetupMain} /> 
      <Route path={`${match.url}/Grades`} component={Grades} /> 
      <Route path={`${match.url}/JobTitles`} component={JobTitles} />
      <Route path={`${match.url}/Jobdescription`} component={Jobdescription} />  
      <Route path={`${match.url}/JobDescription`} component={JobDescription} />
      <Route path={`${match.url}/Section`} component={Section} /> 
      <Route path={`${match.url}/Function`} component={Function} />  
      <Route path={`${match.url}/organisationchart`} component={OrganizationChartLanding} /> 
   </Switch>
)};

export default OrganisationchartRoute;