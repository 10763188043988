/**
 * Crm Routes
 */
/* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Benefits from './Benefits';
import PayScaleLanding from './PayScale';
const PayrollRoute = ({ match }) => {
    return (
        <Switch>
            <Route path={`${match.url}/Benefits`} component={Benefits} />
            <Route path={`${match.url}/Payscale`} component={PayScaleLanding} />

        </Switch>
    )
};

export default PayrollRoute;
