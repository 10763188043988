import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TalentProfile from './talentProfile';
import SuccessionPlanLanding from './succesionLanding';
import SuccessionPlanFormLanding from './SuccessionPlan/PlanForms/formLanding';
import ReviewMeetingForm from './ReviewMeeting/form';
const DevelopLanding = ({ match }) => {
    return (
        <Switch>
            <Route path={`${match.url}/Profile`} component={TalentProfile} />
            <Route path={`${match.url}/Succession`} component={SuccessionPlanLanding} />
            <Route path={`${match.url}/successionPlanForm`} component={SuccessionPlanFormLanding} />
            <Route path={`${match.url}/talentReviewMeeting`} component={ReviewMeetingForm} />
        </Switch>
    )
};

export default DevelopLanding;
