/**
 * App Routes
 */
import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
 

import Header from './header';
import SidebarContent from './tasksidebar';

class Tasklayout extends Component {
	render() {
		const { match } = this.props;
		return (
			<div className="main-wrapper">
				<Header/>
				<div>
					 
				</div>				
				<SidebarContent/>
			</div>
		);
	}
}
export default withRouter(Tasklayout);