import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RecognitionMain from './form'; 

const RecognitionRoute= ({ match }) => {
   return (
   <Switch>
      <Route path={`${match.url}`} component={RecognitionMain} />      
   </Switch>
)};

 

export default RecognitionRoute; 