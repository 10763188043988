import * as Yup from 'yup';

export const NewEmployeeSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Please provide Name'),
    lastName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Please provide Name'),
    email: Yup.string()
        .email('Invalid email')
        .required('Please provide email'),
    doj: Yup.string()
        .required('Please provide date of joining'),


});

export const EmployeeSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .matches(/^[^0-9]*$/, 'First name should not contain numbers')
        .required('Please provide Name'),
    lastName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .matches(/^[^0-9]*$/, 'Last name should not contain numbers')
        .required('Please provide Name'),
    email: Yup.string()
        .email('Invalid email')
        .required('Please provide email'),
    phone: Yup.string()
        .matches(/^[^a-zA-Z]*$/, 'Phone number should not contain alphabets')
        .matches(/^[0-9+() -]*$/, 'Please provide valid phone number')
        .test('phone-length', 'Please provide valid phone number 7 to 15 digits', value => {
            if (!value) return false;
            const numericValue = value.replace(/\D/g, '');
            return numericValue.length >= 7 && numericValue.length <= 15;
        }),
    dob: Yup.date()
        .nullable()
        .max(new Date(),'Please provide a valid date'),
        // .required('Please provide date of birth')
    // gender: Yup.string()
    //     .required("Please select gender"),
    // bloodGroup: Yup.string()
    //     .required("Please select Blood Group"),
    // maritalStatus: Yup.string()
    //     .required("Please select Marital Status"),

    // religionId: Yup.number()
    //     .min(1, "Please select Religion"),
    // languages: Yup.array().min(1, 'Select at least one language'),
    // totalExperience: Yup.string()
    //     .required("Please provide Total Experience"),

});

export const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .required("Please enter password")
})

export const UpdateUsernameSchema = Yup.object().shape({
    newUserName: Yup.string()
        .email("Please enter valid Email")
        .required("Please enter Username")
})

export const attendanceByAdminSchema = Yup.object().shape({
    employeeId: Yup.string()
        .required('Please Select employee'),
    date: Yup.string()
        .required('Please Select Date'),
    inTiming: Yup.string()
        .required('Please Select In Time')
        .test('is-inTiming-date-matching', 'In Timing date should match with Date', function (value, { parent }) {
            const inTimingDate = value?.substring(0, 10);
            const dateDate = parent?.date;
            if (inTimingDate === dateDate) {
                return true;
            }
            else {
                return false;
            }
        }),
    outTiming: Yup.string()
        .required('Please Select Out Time')
        .test('is-outTiming-greater', 'Out Time must be greater than In Time', function (value, { parent }) {
            const inTiming = parent.inTiming;
            const outTiming = value;
            if (!inTiming) {
                return false;
            }
            if (inTiming && outTiming) {
                return outTiming > inTiming;
            }
            return true;
        }),
});

export const BankDetailsSchema = Yup.object().shape({
    accountHolderName: Yup.string()
        .required('Please Enter Account Holder Name'),
    accountNumber: Yup.string()
        .required('Please Enter Account Number'),
    bankName: Yup.string()
        .required('Please Enter Bank Name'),
    ibanNumber: Yup.string()
        .required('Please Enter IBAN Number'),
    branchLocation: Yup.string()
        .required('Please Enter Branch-Location ')
});

export const SalaryBasicAndModeSchema = Yup.object().shape({
    basicSalary: Yup.number()
        .typeError('Basic salary must be a number')
        .min(1, 'Basic salary must be greater than or equal to 1')
        .required('Please enter Basic Salary'),
    salaryCalculationMode: Yup.string()
        .required('Please select Salary Calculation Mode').nullable('Please select Salary Calculation Mode'),

});