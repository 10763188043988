import { DatePicker } from 'antd';
import moment from 'moment';
import React, { Component } from 'react'
import { getDocumentInformation } from '../../Employee/detail/service';
import PayslipViewer from './payslipViewer';

export default class MonthlyPayslip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMonth: 'July 2024',
            data: []
        }
    }
    componentDidMount() {
        getDocumentInformation(551).then(res => {
            if (res.status == "OK") {
                this.setState({
                    data: res.data,
                })
            }
        })
    }


    render() {
        const months = [

            'July 2024', 'June 2024', 'May 2024', 'April 2024', 'March 2024', 'February 2024', 'January 2024',
        ]
        const { selectedMonth, data } = this.state
        console.log('cell : =', data)
        const file = data[1]?.fileName
        return (
            <div className="EmpPay-home-page">
                <div className="slipSidebar">
                    <DatePicker style={{
                        margin: '7px',
                        borderRadius: '6px',
                        width: '193px'
                    }} className='form-control' defaultValue={moment()} picker="year" />
                    {months.map(month => (

                        <div
                            key={month}
                            className={`payslip-month-btn ${selectedMonth === month ? 'active' : ''}`}
                            onClick={() => this.handleMonthSelect(month)}
                        >
                            <span className='float-left'>  {month} </span>
                            <span className='float-right'>  <i class="fa fa-chevron-right" aria-hidden="true"></i> </span>

                        </div>
                    ))}
                </div>

                <div>
                    <PayslipViewer ></PayslipViewer>
                </div>

            </div>
        )
    }
}
