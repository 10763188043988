import React, { Component } from 'react';
import EmployeeProfilePhoto from '../../../CompanyApp/Employee/widgetEmployeePhoto';

export default class ReactionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'All',
    };
  }

  handleTabClick = (tabName) => {
    this.setState({ selectedTab: tabName });
  };

  render() {
    const { selectedTab } = this.state;

    const reactionList = [
      { name: 'Isac Mark', position: 'Finance Manager ', iconId: 1, id: 21 },
      { name: 'Jhon David', position: 'Support Services', iconId: 2, id: 23 },
      { name: 'Vivek Kaig', position: 'Marketing Lead', iconId: 3, id: 25 },
      { name: 'Anil Kumar', position: 'Admin', iconId: 4, id: 26 },
    ];

    const tabsList = {
      1: "👍 Like",
      2: "👏 Celebrate",
      3: "🤲 Support",
      4: "❤️ Love",
      5: "💡 Insightful",
      6: "😄 Funny",
    };

    const profileImojis = {
      1: "👍",
      2: "👏",
      3: "🤲",
      4: "❤️",
      5: "💡",
      6: "😄",
    };

    // Filter tabsList based on iconId
    const availableTabs = Object.entries(tabsList).filter(([iconId]) =>
      reactionList.some(item => item.iconId === parseInt(iconId))
    );

    const mainTabs = availableTabs.slice(0, 2);
    const moreTabs = availableTabs.slice(2, 6);

    const filteredReactionList = selectedTab === 'All'
      ? reactionList
      : reactionList.filter(item => tabsList[item.iconId] === selectedTab);

    return (
      <div style={{ marginTop: '-20px', minHeight: '28em' }}>
        <div className="row user-tabs">
          <div className="page-headerTab p-0">
            <div className="reactionList p-0 col-lg-12 col-md-12 col-sm-12 sub-nav-tabs">
              <ul className="nav reaction-items">
                <li className="nav-item">
                  <a
                    data-toggle="tab"
                    className={`nav-link ${selectedTab === 'All' ? 'active' : ''}`}
                    onClick={() => this.handleTabClick('All')}
                  >
                    All
                  </a>
                </li>
                {/* Render the first two tabs */}
                {mainTabs.map(([iconId, label]) => (
                  <li key={iconId} className="nav-item">
                    <a
                      data-toggle="tab"
                      className={`nav-link ${selectedTab === label ? 'active' : ''}`}
                      onClick={() => this.handleTabClick(label)}
                    >
                      {label}
                    </a>
                  </li>
                ))}
                {/* Render the "More" dropdown if there are more tabs */}
                {moreTabs.length > 0 && (
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      More
                    </a>
                    <div className="dropdown-menu">
                      {moreTabs.map(([iconId, label]) => (
                        <a
                          key={iconId}
                          className={`dropdown-item ${selectedTab === label ? 'active' : ''}`}
                          onClick={() => this.handleTabClick(label)}
                          href="#"
                        >
                          {label}
                        </a>
                      ))}
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>

        {filteredReactionList.map((item, index) => (
          <div key={index} style={{ marginLeft: '-14px', marginTop: '11px' }}>
            <tr>
              <td>
                <div className='d-flex'>
                  <EmployeeProfilePhoto className='createPostProPic' id={item.id}></EmployeeProfilePhoto>
                  <span style={{
                    position: 'absolute',
                    marginTop: '26px', fontSize: '13px',
                    left: '43px'
                  }}>
                    {profileImojis[item.iconId]}
                  </span>
                  <div className='ml-2 mt-1'>
                    <span style={{ fontWeight: 'bold' }}>{item.name}</span> <br />
                    <span>{item.position}</span>
                  </div>
                </div>
              </td>
            </tr>
          </div>
        ))}
      </div>
    );
  }
}
