import * as Yup from 'yup';


export const LeaveTypeSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Please provide Title'),
    days: Yup.number()
        .min(1, 'Too Short!')
        .required('Please provide Days')
        .typeError('Please enter number only')
});