
import React, { Component } from 'react';
import { Modal, SafeAnchor } from 'react-bootstrap';
import { getAttendancePhotos } from './service';
const { Header, Body, Footer, Dialog } = Modal;
export default class LocationListColumn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            location: props.location || "0,0",
            id: props.id,
            photoIn: props.photoIn || null,
            photoOut: props.photoOut || null
        }
    }
    
    fetchPhotos = (callback) => {
        getAttendancePhotos(this.state.id).then(res => {

            if (res.status == "OK") {
              this.setState({
                photoIn: res.data.photoIn,
                photoOut: res.data.photoOut,
                showDetail:true
              })
            }
        })
    }
    openMap = (location) => {
        window.open(`http://maps.google.com/maps?q=${location}`, '_blank');
    }

    openPopup = () => {
        this.fetchPhotos();
    }

    closePopup = () => {
        this.setState({
            showDetail: false
        })
    }
    render() {

        const { location, photoIn,  photoOut } = this.state;
        const { locationOut} = this.props;

        return <>
            <SafeAnchor onClick={this.openPopup}>View</SafeAnchor>

            <Modal enforceFocus={false} size={"md"} show={this.state.showDetail} onHide={this.closePopup} >
                <Header closeButton>
                    <h5 className="modal-title">Location & Selfie</h5>
                </Header>
                <Body>
                    <table className='table table-bordered text-center'>
                        <tbody>
                        <tr>
                            <th>In</th>
                            <th>Out</th>
                        </tr>
                        <tr>
                            <td>
                                <SafeAnchor onClick={() => { this.openMap(location) }}>View Location</SafeAnchor>
                            </td>
                            <td>
                                {locationOut ?
                                    <SafeAnchor onClick={() => { this.openMap(locationOut) }}>
                                        View Location</SafeAnchor> : "NA"}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {photoIn ? <><img style={{ maxWidth: "100%" }} alt={'Photo In'} src={'data:image/jpeg;base64,' + photoIn} /></> : "NA"}
                            </td>
                            <td>
                                {photoOut ? <><img style={{ maxWidth: "100%" }} alt={'Photo Out'} src={'data:image/jpeg;base64,' + photoOut} /></> : "NA"}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Body>


            </Modal>
        </>;
    }
}