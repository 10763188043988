

import { ConfigProvider, DatePicker, Empty } from 'antd';
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import React, { Component } from 'react';
import Chart from "react-apexcharts";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getEmployeemonthlySalary } from './service';
import { generatePayslips, getPayslips } from '../PaySlip/service';
import { toast } from 'react-toastify';
import { getEmployeeId, getPayrollType, verifyApprovalPermission, verifyViewPermission } from '../../../utility';
import { Button, Grid } from '@mui/material';
import moment from 'moment';
import { FormGroup } from 'reactstrap';
import PayslipUAE02Viewer from '../PaySlip/uae02view';
import PayslipViewer from '../PaySlip/view';




export default class MyPayslipCard extends Component {
    constructor(props) {
        super(props);
        const data = [
            { 'Basic Salary': 2500 },
            { 'Allowance': 400 },
            { 'Earning': 500 },
            { 'Deduction': 350 },
        ];

        // Extract labels and series from data
        const labels = data.map(item => Object.keys(item)[0]);
        const series = data.map(item => Object.values(item)[0]);
        const total = series.reduce((acc, value) => acc + value, 0);
        this.state = {
            year: '2024',
            active: false,
            comparison: '0',
            payslipGenerated: false,
            employeeId: getEmployeeId(),
            month: new Date().toLocaleString('en-US', { month: '2-digit' }),
            year: new Date().getFullYear().toString(),
            series: series,
            data: '',

            options: {
                chart: {
                    width: 380,
                    type: 'donut',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                total: {
                                    show: true,
                                    label: 'Total Salary',
                                    formatter: () => '7000'
                                }
                            }
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                        const value = series[seriesIndex];
                        const percentage = ((value / total) * 100).toFixed(1);
                        const label = w.config.labels[seriesIndex];
                        return (
                            `<div class="arrow_box">
                      <span>${label}: ${percentage}%</span>
                    </div>`
                        );
                    }
                },
                dataLabels: {
                    enabled: false
                },
                labels: labels,
                fill: {
                    type: 'gradient',
                },
                legend: {
                    formatter: function (val, opts) {
                        return val + " = " + opts.w.globals.series[opts.seriesIndex]
                    }
                },
                title: {
                    text: 'Current month salary scale'
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },


        };
    }


    componentDidMount() {
        this.fetchList();
        // getEmployeemonthlySalary().then(res => {
        //     if (res.status == "OK") {
        //     }
        // })
    }


    fetchList = () => {
        //  getMyPayslips(salaryMonth).then(res => {
        //     if (res.status == "OK") {
        //         this.setState({
        //             data: res.data,
        //         })
        //     }
        // })
    }
    getSalaryMonth = () => {
        let { month, year } = this.state;
        month = month.toString().length == 1 ? "0" + month : month;
        return `${year}-${month}`;
    }
    handlePayslipGenerator(date, dateString) {
        getPayslips(dateString).then(res => {
            if (res.status == "OK") {
                toast.success(res.message);
            } else {
                toast.error(res.message);
            }
        })
    }

    handleSalaryComparison = (e) => {
        this.setState({ comparison: e.target.value })
    }


    generatePDF = () => {
        const input = document.getElementById('payslip');
        html2canvas(input).then(function (canvas) {
            canvas.getContext('2d');
            var imgWidth = canvas.width;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var top_left_margin = 15;
            var PDF_Width = imgWidth + (top_left_margin * 2);
            var PDF_Height = (PDF_Width * 2) + (top_left_margin * 2);
            var totalPDFPages = Math.ceil(imgHeight / PDF_Height) - 1;
            var imgData = canvas.toDataURL("image/png", 1.0);
            var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
            pdf.addImage(imgData, 'PNG', top_left_margin, top_left_margin, imgWidth, imgHeight);
            for (var i = 1; i <= totalPDFPages; i++) {
                pdf.addPage([PDF_Width, PDF_Height], 'p');
                pdf.addImage(imgData, 'PNG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), imgWidth, imgHeight);
            }

            pdf.save("Payslip" + Date().toLocaleString() + ".pdf");
        });
    };

    render() {
        const { payslipGenerated, text, desc, employeeId, comparison } = this.state

        const monthlyData = [
            {
                "name": "January",
                "Salary": 2400,
            },
            {
                "name": "February",
                "Salary": 1398,
            },
            {
                "name": "March",
                "Salary": 9800,
            },
            {
                "name": "April",
                "Salary": 3908,
            },
            {
                "name": "May",
                "Salary": 4800,
            },
            {
                "name": "June",
                "Salary": 3800,
            },

        ]
        const yearlyData = [
            {
                "name": "2019",
                "Salary": 0,
            },
            {
                "name": "2020",
                "Salary": 0,
            },
            {
                "name": "2021",
                "Salary": 0,
            },
            {
                "name": "2022",
                "Salary": 13908,
            },
            {
                "name": "2023",
                "Salary": 14800,
            },
            {
                "name": "2024",
                "Salary": 53800,
            },

        ]
        const months = [
            { name: 'Jan', value: false },
            { name: 'Feb', value: false },
            { name: 'Mar', value: false },
            { name: 'Apr', value: false },
            { name: 'May', value: false },
            { name: 'Jun', value: false },
            { name: 'Jul', value: false },
            { name: 'Aug', value: true },
            { name: 'Sep', value: true },
            { name: 'Oct', value: true },
            { name: 'Nov', value: true },
            { name: 'Dec', value: true }
        ];
        const disableFutureYears = (current) => {
            return current && current.year() > new Date().getFullYear();
        };
        return (
            <>


                <div className=''>
                    <div className='d-flex'>
                        <div className='ml-5 myPayCard col-md-5' style={{ height: '21em' }}>
                            <div className="Title d-flex">
                                <h2 className='newDashboardTitleAction'>Payslip</h2>

                            </div>
                            <div className='myPayslip-Section'>
                                <ConfigProvider locale={moment.locale('en-gb')}>
                                    <DatePicker
                                        // onChange={this.handlePayslipGenerator}
                                        disabledDate={disableFutureYears}
                                        picker="year"
                                        className='mypay form-control neo-datePicker'
                                        placeholder='YYYY'
                                        format="YYYY"
                                        defaultValue={moment()}
                                    />
                                </ConfigProvider>
                                <div className="mt-1 payslip-status">
                                    <div>
                                        {/* {months.map((item , index) => (
                                            <Button sx={{ textTransform: 'none'}} key={index} size="small" onClick={this.generatePDF()} className='mt-4' disabled={item.value} variant="contained">{item.name} <i className='ml-2 fa fa-download'></i></Button>
                                        )) } */}
                                        <Grid container spacing={0} columns={{ xs: 4, sm: 8, md: 12 }}>
                                            {months.map((item, index) => (
                                                <Grid item xs={1} sm={2} md={3} key={index}>
                                                    <Button sx={{ background: '#64b5f6', textTransform: 'none' }} className='mySlip-btn m-2' size="medium" onClick={(e) => this.generatePDF(e)} disabled={item.value} variant="contained">{item.name} <i className='ml-2 fa fa-download'></i></Button>
                                                </Grid>
                                            ))}
                                        </Grid>

                                    </div>
                                    {/* <Empty style={{ lineHeight: '0.5715', fontWeight: '500' }} className='mt-2' description={'No payslips generated'} /> */}
                                    {/* <p className="no-payslip">
                                No payslips generated
                            </p>
                            <p className="message">
                                Please select another month. Payslips for this month is not yet generated.
                            </p> */}

                                </div>
                            </div>
                        </div>
                        <div className='ml-5 myPayCard col-md-5' style={{ width: '100%', height: '21em' }}>
                            <span style={{ float: 'left', fontWeight: '500' }}>Previous six {comparison === '0' ? 'months' : 'years'} salary (Comparison)</span>
                            <select onChange={(e) => { this.handleSalaryComparison(e) }} className='rounded p-1 float-right'>
                                <option value="0">Monthly</option>
                                <option value="1">Yearly</option>
                            </select>
                            <div style={{ marginTop: '55px' }}>
                                <LineChart width={520} height={220} data={comparison === '0' ? monthlyData : yearlyData}
                                    margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" padding={{ left: 20, right: 10 }} />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="Salary" stroke="#8884d8" strokeWidth={4} />
                                </LineChart>
                            </div>
                        </div>
                    </div>
                    <div className='ml-5 myPayCard col-md-5' style={{ width: '100%', height: '19em' }}>
                        <div style={{ textAlign: '-webkit-center' }} className=" mixed-chart">
                            <Chart options={this.state.options} series={this.state.series} type="donut" height={200} />
                        </div>
                        <p className='font-weight-bold'>Total Salary: 7000</p>
                    </div>
                    {/* <PayslipViewer payslip={payslip}></PayslipViewer> */}
                </div>
            </>
        );
    }
}

