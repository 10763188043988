import React, { Component } from 'react'

export default class PaySummary extends Component {
    render() {
        return (
            <div className="EmpProfile-home-page">
                <div className="mr-4 EmpProfileCenter">
                    <div style={{ textAlign: '-webkit-left' }}  >

                        <div className="mt-3 gnInfo-general-info-card">
                            <div className="gnInfo-card-header">
                                <span className="gnInfo-card-title">General Info</span>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div className="gnInfodetail">
                                        <span className="gnInfolabel">Designation</span>
                                        <span className="gnInfovalue">Accountant</span>
                                    </div>
                                </div>
                                <div class="col">
                                    <div className="gnInfodetail">
                                        <span className="gnInfolabel">Department</span>
                                        <span className="gnInfovalue">Finance</span>
                                    </div>
                                </div>
                                <div class="w-100"></div>
                                <div class="col">
                                    <div className="gnInfodetail">
                                        <span className="gnInfolabel">Job Type</span>
                                        <span className="gnInfovalue">Full Time</span>
                                    </div>

                                </div>
                                <div class="col">
                                    <div className="gnInfodetail">
                                        <span className="gnInfolabel">Line Manager</span>
                                        <span className="gnInfovalue">Will Smith</span>
                                    </div>

                                </div>
                                <div class="w-100"></div>
                                <div class="col">
                                    <div className="gnInfodetail">
                                        <span className="gnInfolabel">Date Of Joining</span>
                                        <span className="gnInfovalue">20 Mar,2023</span>
                                    </div>

                                </div>
                                <div class="col">
                                    <div className="gnInfodetail">
                                        <span className="gnInfolabel">Salary Type</span>
                                        <span className="gnInfovalue">Timesheet</span>
                                    </div>

                                </div>
                                <div class="w-100"></div>
                                <div class="col">
                                    <div className="gnInfodetail">
                                        <span className="gnInfolabel">Home Country</span>
                                        <span className="gnInfovalue">Dubai</span>
                                    </div>

                                </div>
                                <div class="col">
                                    <div className="gnInfodetail">
                                        <span className="gnInfolabel">Grade</span>
                                        <span className="pay-gradestyle gnInfovalue">25</span>
                                    </div>
                                </div>
                                <div className="mt-3 gnInfo-card-header">
                                    <span className="gnInfo-card-title">Legal Info</span>
                                </div>

                                <div class="col">
                                    <div className="gnInfodetail">
                                        <span className="gnInfolabel">Emirates ID</span>
                                        <span className="gnInfovalue">786-5458-585</span>
                                    </div>
                                </div>
                                <div class="col">
                                    <div className="gnInfodetail">
                                        <span className="gnInfolabel">Labour Card</span>
                                        <span className="gnInfovalue"> 28890</span>
                                    </div>
                                </div>
                                <div class="w-100"></div>
                                <div class="col">
                                    <div className="gnInfodetail">
                                        <span className="gnInfolabel">Passport</span>
                                        <span className="gnInfovalue"> 455586</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="EmpProfileRight">
                    <div  >
                        <div className="mt-3 ctc-breakup-card">
                            <div className="ctc-card-header">
                                <span className="ctc-card-title">Salary Breakup</span> <br />
                                <span className="ctc-revision-date">Revised on Apr 14th, 2024</span>
                            </div>
                            <div className="ctc-card-details">
                                <div className="ctc-detail-row">
                                    <span className="ctclabel">Basic Salary</span>
                                    <span className="ctcvalue">3000</span>
                                </div>
                                <div className="ctc-detail-row">
                                    <span className="ctclabel">Allowance</span>
                                    <span className="ctcvalue">3000</span>
                                </div>

                                <div className="ctc-detail-row ctc-net-pay">
                                    <span className="ctclabel">Net Pay</span>
                                    <span className="ctcvalue">AED 6000</span>
                                </div>
                            </div>
                        </div>
                        <div className="bank-card">
                            <div className="bank-header">
                                <span className="ctc-card-title">Bank Info</span> <br />
                            </div>
                            <div className="bank-details">
                                <div className="bank-detail">
                                    <span className="bank-label">Bank name</span>
                                    <span className="bank-value">Abu Dhabi Commercial Bank</span>
                                </div>
                                <div className="bank-detail">
                                    <span className="bank-label">IBAN</span>
                                    <span className="bank-value">52550000061120084</span>
                                </div>
                                <div className="bank-detail">
                                    <span className="bank-label">Account number</span>
                                    <span className="bank-value">4634 3435 3453 5755</span>
                                </div>
                                <div className="bank-detail">
                                    <span className="bank-label">Branch Name</span>
                                    <span className="bank-value">Daira City Center</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
