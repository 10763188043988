/**
 * Crm Routes
 */
/* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Language from './Language';
import Branch from './Branch';
import Department from './Department';
import Designation from './Designation';
import DocumentType from './DocumentType';
import LeaveType from './LeaveType';
import Holiday from './Holiday';
import AllowanceType from './Allowance';
import OrgSetupForm from './OrgSetup/form';
import GratuitySettingForm from './gratuity/GratuitySettingForm';
import OvertimeSettingForm from './overtime/OvertimeSettingForm';
import PerformanceLanding from './Performance/PerformanceLanding';
import Announcement from './Announcement';
import Country from './Country';
import Signature from './Signature';
import Template from './Template/index';
import DocumentRequest from './DocumentRequest/index';
import DocumentRequestView from './DocumentRequest/view';
import SocialShareApproval from './SocialShare';
import Recognition from './Recognition';
import LeaveLanding from './LeaveLanding';
import EmployeeLanding from './EmployeeLanding';
import PayrollLanding from './PayrollLanding';
import ProjectLanding from './ProjectLanding';
import ShiftIndex from './Shifts/index';
import policiesDocument from './PoliciesDocuments';
import Benefits from './Benefits/index';
const CompanyModuleSetupRoute = ({ match }) => {
   return (
      <Switch>
         {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/admin`} /> */}

         <Route path={`${match.url}/country`} component={Country} />
         <Route path={`${match.url}/language`} component={Language} />
         <Route path={`${match.url}/branch`} component={Branch} />
         <Route path={`${match.url}/designation`} component={Designation} />
         <Route path={`${match.url}/department`} component={Department} />
         <Route path={`${match.url}/leave-type`} component={LeaveType} />
         <Route path={`${match.url}/document-type`} component={DocumentType} />
         <Route path={`${match.url}/holiday`} component={Holiday} />
         <Route path={`${match.url}/allowance-type`} component={AllowanceType} />
         <Route path={`${match.url}/gratuity`} component={GratuitySettingForm} />
         <Route path={`${match.url}/overtime`} component={OvertimeSettingForm} />
         <Route path={`${match.url}/performance`} component={PerformanceLanding} />
         <Route path={`${match.url}/announcement`} component={Announcement} />
         <Route path={`${match.url}/social-share`} component={SocialShareApproval} />
         <Route path={`${match.url}/orgsetup`} component={OrgSetupForm} />
         <Route path={`${match.url}/signature`} component={Signature} />
         <Route path={`${match.url}/template`} component={Template} />
         <Route exact path={`${match.url}/document-request`} component={DocumentRequest} />
         <Route path={`${match.url}/Recognition`} component={Recognition} />
         <Route path={`${match.url}/employee`} component={EmployeeLanding} />
         <Route path={`${match.url}/leave`} component={LeaveLanding} />
         <Route path={`${match.url}/payroll`} component={PayrollLanding} />
         <Route path={`${match.url}/project`} component={ProjectLanding} />
         <Route path={`${match.url}/Shifts`} component={ShiftIndex} />
         <Route path={`${match.url}/policiesdocuments`} component={policiesDocument} />
         <Route path={`${match.url}/benefits`} component={Benefits} />
      </Switch>
   )
};

export default CompanyModuleSetupRoute;
