import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Main from "./Entryfile/Main";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";
import { usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-promise-loader';
import { getPrimaryColor } from "./utility";

const composer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, composer(applyMiddleware(thunk)));

ReactDOM.render(<Provider store={store}>
    <Main />
<Loader zIndex={1010} color={getPrimaryColor()} background={'rgba(0,0,0,.5)'} loading={false} promiseTracker={usePromiseTracker} />
</Provider>, document.getElementById('app'));

if (module.hot) { // enables hot module replacement if plugin is installed
    module.hot.accept();
}