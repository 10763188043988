/**
 * Crm Routes
 */
/* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Timesheet from './list';

const TimesheetRoute = ({ match }) => {
   return (
   <Switch>
      <Route path={`${match.url}`} component={Timesheet} />  
   </Switch>
)};

export default TimesheetRoute;
